import $ from 'jquery';
import SETTINGS from './settings';
import '../scss/index.scss';

// const referral = parse.location.search

// window.FEEDOT_SETTINGS = {
//     referral: "ddf9a18abf8770c0249e7d393467e4ba",
//     widgets: [      
//         {
//             id: 103,
//             type: 'quiz',
//             customisation: {
//                 desktop: {
//                     categoryId: -1,
//                     operatorName:"Михаил",
//                     operatorSurname: "Кузнецов",
//                     operatorDescription: "Специалист по списанию долгов",
//                     operatorAvatar: "/images/avatars/7.jpg",
//                     themeCustomColor1: "#017951",
//                 }, 
//             }
//         },
//     ]  
// };

$(()=> {
    SETTINGS.ymId && ym(+SETTINGS.ymId, 'init', {});
});

