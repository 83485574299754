import getParams from './utils/get-params';

const SETTINGS = {
    partnerId: getParams.cd_referral,
    data1: getParams.data1,
    data2: getParams.data2,
    phones: (getParams.phones || '').split(',').filter((v) => v.trim().length),
    ymId: getParams.ymId,
    gaId: getParams.gaId
};

export default SETTINGS;